import Container from 'react-bootstrap/Container';
import { React, useState, useEffect } from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Collapse from 'react-bootstrap/Collapse';

import { Link, Outlet, useLocation } from 'react-router-dom'

const Navigation = () => {
  // to open currency flag
  const [open, setOpen] = useState(false);

// to add active class
  const location = useLocation(); // once ready it returns the 'window.location' object
  const [url, setUrl] = useState(null);
  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);
//  end   to add active class
  return (

    <>

      <div className='pt-0 d-block desktop-navigation'>

        <Navbar  className='pt-0'>
          <Nav className="me-auto d-flex flex-column left-navigation" as="ul" >
            <Nav.Item as="li">
              <Nav.Link as={Link} to="/"
                className={"" + (url === "/" ? " active" : "")} >
                <img src='assets/images/nav-icon-one.png'></img>
              </Nav.Link>

            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link as={Link} to="/dashboard" className={"" + (url === "/dashboard" ? " active" : "")}  >
                <img src='assets/images/nav-icon-two.png'></img>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link as={Link} to="/referrals" className={"" + (url === "/referrals" ? " active" : "")}  >
                <img src='assets/images/nav-icon-three.png'></img>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link as={Link} to="/profile" className={"" + (url === "/profile" ? " active" : "")} >
                <img src='assets/images/nav-icon-four.png'></img>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link as={Link} to="/ordercard" className={"" + (url === "/ordercard" ? " active" : "")} >
                <img src='assets/images/nav-icon-five.png'></img>
                </Nav.Link>
            </Nav.Item>

          </Nav>
        </Navbar>

        <Outlet />
      </div>


      <div className="me-auto pt-4 pb-4  mt-2 left-navigation left-navigation-bottom d-block desktop-navigation" onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}>
        <img src='assets/images/nav-icon-six.png'></img>
        <Collapse in={open}>
          <div id="example-collapse-text">
            <img src='assets/images/nav-icon-seven.png' className='mb-3 mt-3'></img>
            <img src='assets/images/nav-icon-eight.png'></img>
          </div>
        </Collapse>
      </div>



    </>
  )
}

export default Navigation
