// import { React, useState } from 'react';
import * as React from 'react';
import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Navigation from './components/Navigation';

import { Route, Link, BrowserRouter as Router, Routes } from 'react-router-dom'
// import ConnectWallet from './components/ConnectWallet';
// import FirstTimeUser from './components/FirstTimeUser';
// import Dashboard from './components/Dashboard';
// import Referrals from './components/Referrals';
// import OrderMyCard from './components/OrderMyCard';
// import TrackMyOrder from './components/TrackMyOrder';
// import Profile from './components/Profile';

const ConnectWallet = React.lazy(() => import('./components/ConnectWallet'));
const FirstTimeUser = React.lazy(() => import('./components/FirstTimeUser'));
const Dashboard = React.lazy(() => import('./components/Dashboard'));
const Referrals = React.lazy(() => import('./components/Referrals'));
const OrderMyCard = React.lazy(() => import('./components/OrderMyCard'));
const TrackMyOrder = React.lazy(() => import('./components/TrackMyOrder'));
const Profile = React.lazy(() => import('./components/Profile'));

function App() {
  return (
    <div className="App">
 <React.Suspense fallback={<div className='text-white'> Please Wait... </div>} >
      <Router>

        <Routes>
         
            <Route exact path="/" element={<ConnectWallet />}>
          
        </Route>
        <Route exact path="/firstuser" element={<FirstTimeUser />}>

        </Route>
        <Route exact path="/dashboard" element={<Dashboard />}>

        </Route>
        <Route exact path="/referrals" element={<Referrals />}>

        </Route>
        <Route exact path="/ordercard" element={<OrderMyCard />}>

        </Route>
        <Route exact path="/trackorder" element={<TrackMyOrder />} >
        </Route>
        <Route exact path="/profile" element={<Profile />} >
        </Route>
      </Routes>

    </Router>
    </React.Suspense>
    </div >

  );
}

export default App;
